<template>
  <el-dialog
    top="80px"
    width="500px"
    class="create-related"
    title="Tạo mới liên kết"
    :visible="visible"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <el-form ref="form" :model="form" :rules="rules" label-position="top">
      <el-form-item label="Loại" prop="placeType">
        <el-select
          v-model="form.placeType"
          filterable
          class="w-100"
          :disabled="true"
        >
          <el-option
            v-for="t in placeTypes"
            :key="t.value"
            :label="t.name"
            :value="t.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Điểm đến" prop="place">
        <el-select
          v-model="form.place"
          class="w-100"
          filterable
          remote
          placeholder="Chọn điểm đến"
          :disabled="callingAPI"
          :remote-method="searchPlace"
          :loading="searching"
        >
          <el-option
            v-for="p in places"
            :key="p.id"
            :label="p.name"
            :value="p.id"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button :disabled="callingAPI" @click="$emit('close')">Hủy</el-button>
      <el-button type="primary" :loading="callingAPI" @click="handleCreateRelated">Tạo liên kết</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { createPlaceRelated, listPlaces } from '@/services/place'
import { getPlaceTypes } from '@/utils/filters'

export default {
  name: 'CreateRelated',
  props: {
    visible: Boolean,
    type: String,
    detail: Object
  },
  data() {
    return {
      callingAPI: false,
      searchText: '',
      searching: false,
      places: [],
      form: {
        placeType: '',
        place: ''
      },
      rules: {
        placeType: [
          {
            required: true,
            message: 'Loại điểm đến không được để trống.',
            trigger: ['blur, change']
          }
        ],
        place: [
          {
            required: true,
            message: 'Điểm đến không được để trống.',
            trigger: ['blur, change']
          }
        ]
      }
    }
  },
  computed: {
    ...mapGetters(['constant', 'language']),
    placeTypes() {
      return getPlaceTypes().filter((p) => p.type === 'place')
    },
    params() {
      return {
        locale: this.language,
        place_types: this.form.placeType,
        per_page: 10,
        search_text: this.searchText
      }
    },
    itemInput() {
      return {
        place_id: this.detail.id,
        related_place_id: this.form.place
      }
    }
  },
  beforeMount() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.form.placeType = this.type
      this.getPlaces()
    },
    getPlaces() {
      this.searching = true
      const request = listPlaces(this.params)
      request.then((res) => {
        this.places = res.result
        this.searching = false
        if (!this.form.place) {
          this.form.place = this.places[0].id
        }
      }).catch(() => {
        this.searching = false
      })
    },
    handleChangePlaceType() {
      this.form.place = ''
      this.searchText = ''
      this.getPlaces()
    },

    searchPlace(text) {
      this.searchText = text
      setTimeout(() => {
        this.getPlaces()
      }, 100)
    },

    handleCreateRelated() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.callingAPI = true
          createPlaceRelated(this.itemInput).then(() => {
            this.$emit('close')
            this.$emit('reload')
            this.$notify.success({
              title: 'Thông báo',
              message: 'Tạo liên kết thành công'
            })
            this.callingAPI = false
          }).catch((error) => {
            this.callingAPI = false
            this.$notify.error({
              title: 'Thông báo',
              message: error && error.response && error.response.data && error.response.data.message || 'Lỗi hệ thống'
            })
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.create-related {
  .el-select {
    width: 100%;
  }
}
</style>
